@import '@/styles/theme/variables.scss';

.footerGrid {
  color: $token-background-primary !important;
}

#footerSection {
  padding: $pageFooter-footerSection-padding;
}

.copyrightBlock {
  justify-self: end !important;

  p {
    margin-bottom: $token-spacing-small;
    font-size: $pageFooter-copyrightBlock-font-size;
    line-height: $token-line-height-loose;
  }
}

.quickLinksList {
  padding-left: $token-spacing-0;

  li:last-child a {
    border-right: none
  }
}

.quicklinksListItem {
  padding-bottom: $token-spacing-0;

  a {
    font-weight: $token-font-weight-reg !important;
    font-size: $right-link-fontsize;
    line-height: $token-line-height-loose;
  }

  p {
    padding-left: $pageFooter-quicklinksListItem-p-padding-left;
  }
}

.footerGridLeft {
  color: $token-background-primary !important;

  .copyrightBlock p {
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: $token-font-weight-reg;
    font-size: $pageFooter-copyrightBlock-font-size;
    line-height: $token-line-height-loose;
    padding-top: $token-spacing-10px;
  }
}

@media #{$usb-max-sm} {
  .footerGridLeft {
    display: flex !important;
    flex-direction: column !important;

    .quickLinksBlock {
      order: 2;
      margin: auto;
    }

    .copyrightBlock {
      order: 1;
      margin: auto;
    }

    .copyrightBlock p {
      font-size: $token-font-size-14px;
      line-height: $token-line-height-flat;
    }

    .quicklinksListItem {
      padding-bottom: $token-spacing-0;

      a {
        font-size: $token-font-size-sm;
        line-height: $token-line-height-flat;
      }
    }
  }

  .footerGrid {
    display: $pageFooter-display !important;

    .quickLinksBlock {
      margin: $pageFooter-block-margin;
      width: $pageFooter-width !important;
      text-align: $pageFooter-text-align !important;
    }

    .copyrightBlock {
      margin: $pageFooter-block-margin;
      width: $pageFooter-width !important;
      text-align: $pageFooter-text-align !important;
      padding: $pageFooter-block-spacing !important;
    }
  }

  .footerSocialIcons {
    display: block !important;
    padding-bottom: $token-spacing-5;
    position: relative;
    left: $token-spacing-12px;
  }
}

.quicklinksListItem {
  display: inline-block;

  a {
    color: $pageFooter-quicklinksListItem-a-color;
    text-decoration: none;
  }
}

.quickLinkWithPipe {
  border: $token-border-none;
}

.quickLinkWithPipeMobile {
  border-right: $pageFooter-quickLinkWithPipeMobile-border-right;
  padding-right: $token-spacing-4;
  margin-right: $token-spacing-4;
  
  span {
    padding-left: $token-spacing-3px;
  }
  
  svg {
    fill: $pageFooter-quickLinkColor-color;
  }
}

.quickLinkNoPipe {
  border: $token-border-none;
}

.quickLinkColor {
  color: $pageFooter-quicklinksListItem-a-color;
}

.copyrightText {
  justify-self: end !important;
  padding: $token-spacing-3 $token-spacing-0 !important;
  color: $token-background-primary;
}

@media screen and (min-width: $token-breakpoint-large) {
  .quickLinkWithPipe {
    border-right: $token-border-width-default solid $pageFooter-quicklinksListItem-a-color;
    padding-right: $token-spacing-4;
    margin-right: $token-spacing-4;
  }
}

.footerSocialIcons {
  display: none;
}

@media (min-width: $token-breakpoint-x-large) {
  .gridWrapper {
    max-width: $pageFooter-gridWrapper-width !important;
  }

  .quickLinksBlock {
    max-width: $token-spacing-1120px !important;
  }
}

@media screen and (min-width: 667px) and (max-width: $token-breakpoint-large) {
  .footerGrid {
    display: $pageFooter-display !important;
  }

  .quicklinksListItem {
    display: $pageFooter-quicklinksListItem-display-mobile;
    padding: $token-spacing-3 $token-spacing-0;
  }
}

@media screen and (min-width: 1180px) and (max-width: $usb-screen-1368) {
  .footerGrid {
    display: $pageFooter-display !important;
  }
}

@media screen and (max-width: $token-breakpoint-medium) {
  .quickLinksBlock {
    margin: $pageFooter-block-margin;
    width: $pageFooter-width !important;
    text-align: $pageFooter-text-align !important;

    .quicklinksListItem {
      display: block;
      padding: $pageFooter-quicklinksListItem-padding-mobile;
  
      .quickLinkWithPipeMobile {
        border: $token-border-none;
        padding-right: $token-spacing-0;
        margin-right: $token-spacing-0;
      }
    }
  }

  .copyrightBlock {
    margin: $pageFooter-block-margin;
    width: $pageFooter-width !important;
    text-align: $pageFooter-text-align !important;
    padding: $pageFooter-block-spacing !important;
  }
}

@media screen and (min-width: 672px) and (max-width: 1056px) {
  .copyrightBlock {
    justify-self: $pageFooter-copyrightBlock-justify-self-tablet;
  }
}

.disclaimerText {
  margin: $token-spacing-7 $token-spacing-0 $token-spacing-0;
  
  p {
    color: $pageFooter-disclaimerText-p-color;
  }
}