@import '@/styles/theme/variables.scss';

.mainHeader {
  display: block;
  position: $pageLayout-mainHeader-position;
  top: $token-spacing-0;
  width: $pageLayout-mainHeader-width;
  z-index: $pageLayout-mainHeader-z-index;
}

.paragraph {
  @include paragraph;

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-40px;
  }

  ol {
    @include listOl;
    padding-inline-start: $token-spacing-40px;
  }

  p {
    @include paragraphP;
  }

  pre,
  ol,
  ul {
    width: 100%;
  }
}

.mainContentStyling {
  .para {
    @extend .paragraph;
  }
  color: $token-text-color-secondary;
  min-height: $pageLayout-mainContentStyling-min-height;
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}

@media #{$usb-max-1055} {
  .mainContentStyling {
    min-height: 0vh;
  }
}

.footer {
  border-top: $pageLayout-footer-border-top;
  box-shadow: $pageLayout-footer-box-shadow;

  @media #{$usb-max-sm} {
    border-top: $pageLayout-footer-border-top-mobile;
  }
  
  background-color: $pageLayout-footer-background;
}

.acceptCookiesStyle {
  position: sticky !important;
  bottom: 0 !important;
  z-index: 100 !important;
  background-color: $pageLayout-acceptCookie-background;
  margin-bottom: $token-spacing-4;
  padding-top: $token-spacing-4;
  padding-bottom: $token-spacing-4;
}
