@import '@/styles/theme/variables.scss';

@media screen and (max-width: $token-breakpoint-small) {
  .gridWrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .marginLayout {
    a {
      margin-top: 0rem !important;
    }
  }
}
