@media (max-width: 672px) {
  .marginLayout {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .cookiesBlock:nth-child(2) {
    text-align: center !important;
    width: 100% !important;

    a,
    button {
      display: block !important;
      width: 100% !important;
      margin-left: auto !important;
      margin-top: $token-spacing-4 !important;
    }

    button {
      width: 100% !important;
    }

    a {
      //margin-bottom: $token-spacing-20 !important;
      //top: $token-spacing-16 !important;
      text-decoration: underline !important;
    }
  }

  .cookiesBlock:nth-child(2) {
    display: inline-block !important;
  }
}
