@import '@/styles/theme/variables.scss';

.footerContainer {
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  background: $token-background-brand-primary;
  padding: $token-spacing-8 $token-spacing-0;
}

.footer_grid {
  font-size: $token-font-size-root;
  line-height: $token-line-height-loose;
  color: $token-text-color-inverse-primary;
}

.quickLinksBlock {
  flex-direction: row !important;
}

.footer_grid h2 {
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-spacing-6;
  padding-top: $token-spacing-22-4px !important;
  padding-bottom: $token-spacing-3;
  color: $token-text-color-inverse-primary;
}

.includeExternalIcon::before {
  content: '';
  display: inline-block;
  background-image: url(../../../../public/footerQuickLinks/external.svg);
  background-repeat: no-repeat;
  width: $token-spacing-4;
  height: $token-spacing-4;
  padding-right: $token-spacing-6;
}

.col_1,
.col_2,
.col_3,
.col_4,
.col_5 {
  padding-right: $token-spacing-16;
}

.listOfLinks {
  padding-bottom: $token-spacing-2;

  a {
    color: $token-text-color-inverse-primary;
    text-decoration: none;
  }
} 

.dropdown {
  min-width: $token-spacing-170px;

  button {
    min-width: $token-spacing-150px;
  }

  ul {
    border: none !important;
  }
} 

.socialIcons a {
  padding-right: $token-spacing-4;
}

.supportDetails {
  span {
    font-size: $token-font-size-root;
    line-height: $token-line-height-loose;

    a {
      text-decoration: underline;
      color: $token-text-color-inverse-primary;
    }
  }

  h2 {
    max-width: min-content;
  }
}

.supportBlock {
  border-left: $token-border-width-default solid $token-border-inverse;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-spacing-6;
  margin-top: $token-spacing-22-4px;
  padding: $token-spacing-0 $token-spacing-6 !important;

  div:first-child h2 {
    padding-top: $token-spacing-0 !important;
  }
}

.col_2 div:nth-child(2) {
  position: absolute;
  bottom: $token-spacing-8;
}

@media #{$usb-max-1024} {
  .supportBlock {
    display: none !important;
  }

  .quickLinksBlock {
    flex-wrap: wrap;
  }

  .col_1,
  .col_2,
  .col_3,
  .col_4,
  .col_5 {
    padding-right: $token-spacing-4 !important;
  }
}

@media #{$usb-max-1368} {
  .quickLinkSecurity div:nth-child(2) {
    position: unset !important;
    bottom: unset !important;
  }
}

@media only screen and (max-width: $token-breakpoint-small) {
  .quickLinksBlock {
    flex-wrap: wrap;
  }

  .supportDetails {
    display: none;
  }

  .includeExternalIcon::before {
    display: none !important;
  }

  .col_1,
  .col_2,
  .col_3,
  .col_4,
  .col_5 {
    width: 50%;
  }

  .col_2 div:nth-child(2) {
    position: unset !important;
    bottom: unset !important;
  }

  .col_4 {
    position: relative;
    bottom: $token-spacing-20;
  }

  .quickLinkReporting {
    position: absolute;
    bottom: $token-spacing-128px;
  }

  .dropdown button {
    background-color: $token-background-brand-primary !important;
    border: $token-border-width-default solid $token-border-inverse !important;
    border-radius: $token-rounded-sm !important;

    span {
      color: $token-text-color-inverse-primary !important;
    }

    svg {
      fill: $icon-light-fill-color !important;
    }
  }

}

@media screen and (min-width: 667px) and (max-width: 910px) {

  .col_2 div:nth-child(2) {
    position: absolute;
    bottom: $token-spacing-144px;
  }

  .quickLinkSecurity div:nth-child(2) {
    position: unset;
    bottom: unset;
  }

  .securityStyle {
    display: block;
  }

  .quickLinkCustomSecurity div:nth-child(2) {
    position: absolute;
    bottom: $token-spacing-8;
  }

  .dropdown {
    button {
      max-width: 70% !important;
      min-width: $token-spacing-0;
      white-space: normal !important;
      text-align: left;
      padding-top: $token-spacing-6 !important;
      padding-bottom: $token-spacing-6 !important;
    }

    ul {
      inset: $token-spacing-12 $token-spacing-small auto $token-spacing-0 !important;
    }
  } 
}

@media screen and (min-width: 667px) and (max-width: 915px) {

  .quickLinkRegion {
    position: absolute;
    top: $token-spacing-160px;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1280px) {

  .col_2 div:nth-child(2) {
    position: absolute;
    bottom: $token-spacing-14;
  }
}

@media only screen and (min-device-width: 540px) and (max-device-width: 720px) and (orientation: portrait) {
  .col_2 div:nth-child(2) {
    position: unset !important;
    bottom: unset !important;
  }

  .col_5 {
    position: absolute;
    top: $token-spacing-250px;
    right: $token-spacing-180px;
  }

  .quickLinkReporting {
    position: relative;
    bottom: $token-spacing-24;
  }

  .quickLinkRegion {
    position: absolute;
    top: $token-spacing-250px;
    right: inherit;
  }
}