@import '@/styles/theme/variables.scss';

.skipLinks {
    a {
        position: absolute !important;

        height: $token-spacing-xs;
        width: $token-spacing-xs;

        padding-bottom: $token-spacing-25px;

        z-index: 3000;
        clip: rect(0 0 0 0);

        &:hover,
        &:active,
        &:focus {
            outline: -webkit-focus-ring-color auto $token-spacing-xs !important;
            outline-offset: (-$token-spacing-2);

            clip: auto;

            left: $token-spacing-0;
            top: $token-spacing-0;
            width: 100%;
            height: auto;

            padding: $token-spacing-10px $token-spacing-0;
            margin: $token-spacing-0;

            text-align: center;

            background: $token-background-primary;

            &::before {
                border: none !important;
            }
        }
    }
}