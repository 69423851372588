$pageLayout-acceptCookie-background: $token-color-grey-20;
$pageLayout-acceptCookie-text-color: $token-color-base-dark-blue-gray;
$pageLayout-acceptCookie-link-color: $token-color-brand-interaction;
$pageLayout-acceptCookie-link-decoration: underline;
$pageLayout-acceptCookie-link-float: left;
$pageLayout-acceptCookie-button-bkg-color: $token-color-brand-interaction;
$pageLayout-acceptCookie-gridWrapper-max-width: 1650px;
$pageLayout-acceptCookie-gridWrapper-margin-left: inherit;
$pageLayout-mainContentStyling-min-height: 85vh;
$pageLayout-acceptCookiesStyle-background: $token-background-brand-primary;
$cookieSettings-h1: $token-color-data-5;
$cookieSettings-text-color: $token-color-grey-80;
$cookieSettings-label-span-font-weight: inherit;
$cookieSettings-cookieDescription-margin: $token-spacing-2 $token-spacing-0 $token-spacing-1 $token-spacing-0;
$cookieSettings-cookieDescription-font-size: $token-font-size-xs;
