@import '@/styles/theme/variables.scss';

.cookiesBlock {
  color: $token-text-color-inverse-primary;
}

.cookiesBlockCentered {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.cookiesBlock a {
  color: $token-text-color-inverse-primary;
}


.marginLayout {
  display: flex;
  flex-direction: $cookiesBanner-marginLayout-flex-direction;
  align-self: center;

  p {
    color: $pageLayout-acceptCookie-text-color !important;
  }

  a {
    margin-left: $token-spacing-5;
    color: $pageLayout-acceptCookie-link-color !important;
    text-decoration: $pageLayout-acceptCookie-link-decoration !important;
    float: $pageLayout-acceptCookie-link-float;
    margin-top: $token-spacing-2 !important;
  }

  button {
    background-color: $pageLayout-acceptCookie-button-bkg-color !important;
    float: $pageLayout-acceptCookie-link-float;
    margin: 0 0 0 $token-spacing-5 !important;
  }
}

.cookiesButton {
  background: transparent !important;
  border: 1px solid $token-border-inverse !important;
  color: $token-text-color-inverse-primary;
  font-weight: $token-font-weight-reg !important;
}

.gridWrapper.usb-grid--container.grid.normal-padding {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.cookiesButton:focus,
.cookiesButton:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

// KEEP IN ORDER
@import './styles/max-width-usb-sm.module.scss';
@import './styles/min-width-usb-xl.module.scss';
@import './styles/max-usb-1055.module.scss';
@import './styles/max-width-672.module.scss';

@mixin button-bar($flex-direction) {
  .cookiesBlock:nth-child(2) {
    .marginLayout {
      flex-direction: $flex-direction;
      text-align: center;
      margin: 0 $token-spacing-20 0 0;
      margin: 0;

      button, a {
        margin-top: $token-spacing-16px !important;
      }
    }
  }
}

@media screen and (min-width: 672px) and (max-width: 767px) {
  @include button-bar(column-reverse)
}

@media screen and (min-width: 768px) and (max-width: 1425px) {
  @include button-bar(column-reverse)
}

