@import '@/styles/theme/variables.scss';

.breadcrumbText {
  text-transform: capitalize;
  text-decoration: none;
}

.bgEven {
  background-color: $table-row-secondary-background-color !important;

  ol {
    padding: $token-spacing-0 !important;
  }
}

.breadcrumbTitle {
  p {
    display: inline;
  }
  a {
    color: $breadcrumb-text-color;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

.breadcrumb_ol {
  padding-left: $token-spacing-0 !important;
}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
  .fullWidth {
    max-width:$token-spacing-1120px !important;
  }
}
