@import '@/styles/theme/variables.scss';

.mainContainer {
  height: auto;
  min-height: auto;
}

.header {
  margin-top: $token-spacing-20;
  display: flex;

  h1 {
    font-style: normal;
    font-weight: $token-font-weight-bold;
    font-size: $token-font-size-3xl;
    color: $token-background-inverse-primary;
  }

}


.shortText {
  margin-top: $token-rounded-base;

  p {
    font-style: normal;
    font-weight: $token-font-weight-reg;
    font-size: $token-font-size-base;
    line-height: $token-line-height-loose;
    color: $token-border-dark;
    margin-top: $token-rounded-base;
  }
}

@media #{$token-screen-x-large} {
  .gridWrapper {
    max-width: $token-spacing-1650px !important;
  }
}

.pageLinks {
  margin: $token-rounded-base;

  li {
    list-style: disc;
    color: $token-text-color-primary;
    font-style: normal;
    font-weight: $token-font-weight-med;
    font-size: $token-font-size-base;
    line-height: $token-line-height-loose;
  }

  a {
    text-decoration: underline;
    color: $token-background-brand-interaction;

    &:hover {
      text-decoration: none;
      color: $token-background-brand-interaction;
    }

    &:active {
      color: $token-background-brand-interaction;
    }
  }
}
