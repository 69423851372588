@import '@/styles/theme/variables.scss';

.cookiesBlock {
  .cookiesTitle {
    color: $cookieSettings-h1;
    font-weight: $token-font-weight-bold;
    margin-top: $token-spacing-8;
  }
  
  .eachCookieList {
    margin: $token-spacing-4 $token-spacing-0 $token-spacing-0;

    .defaultCheckStyle {
      margin-bottom: $token-spacing-6;

      label {
        span {
          color: $cookieSettings-text-color;
          font-weight: $cookieSettings-label-span-font-weight;
          line-height: $token-spacing-6;
        }

        svg {
          fill: $token-utility-white !important;
        }
      }

      input:disabled {
        color: $token-text-color-inverse-primary !important;
      }

      input:focus,
      input:active {
        outline: none !important;
        border: none !important;
      }
    }

    .cookieDescription {
      margin: $cookieSettings-cookieDescription-margin;
      color: $cookieSettings-text-color;
      font-weight: $token-font-weight-reg;
      font-size: $cookieSettings-cookieDescription-font-size;
    }
  }
}

@media (min-width: $token-breakpoint-x-large) {
  .gridWrapper {
    max-width: $token-spacing-1650px !important;
  }
}
